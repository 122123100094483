import {
    Briefcase as BriefcaseIcon,
    Clipboard as ClipboardIcon, Database as DatabaseIcon,
    Facebook as FacebookIcon, Filter as FunnelIcon, Grid as GridIcon,
    Layers as LayersIcon, Settings as SettingsIcon, Users as UsersIcon,
    PauseCircle as PauseCircleIcon,
    Pause as Pause
} from "react-feather";
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QRManagement from "../pages/qr-management/qr-management";
import CMSMain from "../pages/cms/CMSMain";
import DeploymentSharedComponent from "../pages/cms/DeploymentSharedComponent/DeploymentSharedComponent";
import Brands from "../pages/crm/brand/Brands";
import NewBrand from "../pages/crm/brand/NewBrand";
import Clients from "../pages/crm/client/Clients";
import NewClient from "../pages/crm/client/NewClient";
import Contacts from "../pages/crm/contact/Contacts";
import NewContact from "../pages/crm/contact/NewContact";
import CustomAudiences from "../pages/facebook-tools/custom-audiences/video/CustomAudiences";
import WebsiteCustomAudienceForm from "../pages/facebook-tools/custom-audiences/website/WebsiteCustAudienceForm";
import PageCustomAudienceForm from "../pages/facebook-tools/custom-audiences/page/PageCustomAudienceForm";

import FacebookABTests from "../pages/facebook-tools/FacebookABTests";
import FacebookPostSwitchTool from "../pages/facebook-tools/post-switch-tool/FacebookPostSwitchTool";
import FacebookPostTool from "../pages/facebook-tools/post-tool/FacebookPostTool";
import AccountFunnels from "../pages/funnels/AccountFunnels";
import FunnelHistory from "../pages/funnels/FunnelHistory";
// Misc
import Landing from "../pages/misc/Landing";
import Accounts from "../pages/prods-and-accounts/account/Accounts";
import NewAccount from "../pages/prods-and-accounts/account/NewAccount";
import Industries from "../pages/prods-and-accounts/industry/Industries";
import NewIndustry from "../pages/prods-and-accounts/industry/NewIndustry";
import NewProduct from "../pages/prods-and-accounts/product/NewProduct";
import Products from "../pages/prods-and-accounts/product/Products";
import TabMenuItems from "../pages/tableaus/administration/tab-menu-items/TabMenuItems";
import ClientReports from "../pages/tableaus/client-reports/ClientReports";
import ClientReportsInternalUsersView from "../pages/tableaus/client-reports/ClientReportsInternalUsersView";
import TabManagement from "../pages/tableaus/crs-tabs-mgmt/TabManagement";
import InternalReports from "../pages/tableaus/tableau-view/InternalReports";
import ExternalUsersMgmt from "../pages/user-mgmt/external-users/ExternalUsersMgmt";
import NewUser from "../pages/user-mgmt/users/NewUser";
import Users from "../pages/user-mgmt/users/Users";
import LookalikesCreator from "../pages/facebook-tools/lookalikes-creator/LookalikesCreator";
import UploadFolderManagement from "../pages/facebook-tools/upload-folder-management/UploadFolderManagement";
import countryDisplaySelection from "../pages/facebook-tools/countryDisplaySelection/CountryDisplaySelections";
import DailyCap from "../pages/daily-cap-tool/DailyCap";
import NewDailyCap from "../pages/daily-cap-tool/NewDailyCap"
import AcceleratedAdsetsTool from "../pages/facebook-tools/AcceleratedAdsetsTool";
import BudgetTool from "../pages/facebook-tools/budget-tool/BudgetTool";
import StopLoss from "../pages/facebook-tools/stop-loss/StoplossTool";
import AutoStopLoss from "../pages/facebook-tools/stop-loss/AutoStopLoss";
import AutomaticAdsetManagement from "../pages/facebook-tools/automatic-adset-management/AutomaticAdsetManagement";

// Landing specific routes
export const landing = [{
    path: "/",
    name: "Landing Page",
    component: Landing,
    children: null
}];

const clientReports = {
    appModuleId: "tableaus-external-views",
    path: "/client-reports",
    name: "Client Reports",
    icon: ClipboardIcon,
    component: ClientReports
}

const clientReportsInternalUsersView = {
    appModuleId: "tableaus",
    path: "/client-reports-internal-users-view",
    name: "All Clients",
    icon: ClipboardIcon,
    component: ClientReportsInternalUsersView
}

const crmRoutes = {
    appModuleId: "crm",
    path: "/crm",
    name: "CRM",
    icon: BriefcaseIcon,
    children: [
        {
            appModuleId: "crm",
            path: "/crm/clients",
            name: "Clients",
            component: Clients
        },
        {
            appModuleId: "crm",
            path: "/crm/new-client",
            name: "New Client",
            component: NewClient
        },
        {
            appModuleId: "crm",
            path: "/crm/brands",
            name: "Brands",
            component: Brands
        },
        {
            appModuleId: "crm",
            path: "/crm/new-brands",
            name: "New Brands",
            component: NewBrand
        },
        {
            appModuleId: "crm",
            path: "/crm/contacts",
            name: "Contacts",
            component: Contacts
        },
        {
            appModuleId: "crm",
            path: "/crm/new-contacts",
            name: "New Contacts",
            component: NewContact
        }

    ]
};

const prodsAndAccountsRoutes = {
    path: "/prods-accounts",
    name: "Products & Accounts",
    icon: GridIcon,
    children: [
        {
            appModuleId: "product",
            path: "/prods-accounts/products",
            name: "Products",
            component: Products
        },
        {
            appModuleId: "product",
            path: "/prods-accounts/new-product",
            name: "New Product",
            component: NewProduct
        },
        {
            appModuleId: "accounts",
            path: "/prods-accounts/accounts",
            name: "Accounts",
            component: Accounts
        },
        {
            appModuleId: "accounts",
            path: "/prods-accounts/new-account",
            name: "New Account",
            component: NewAccount
        },
        {
            appModuleId: "product",
            path: "/prods-accounts/industries",
            name: "Industries",
            component: Industries
        },
        {
            appModuleId: "product",
            path: "/prods-accounts/new-industry",
            name: "New Industry",
            component: NewIndustry
        }
    ]
};

const usersRoutes = {
    path: "/user-mgmt",
    name: "Users",
    icon: UsersIcon,
    children: [
        {
            appModuleId: "users",
            path: "/user-mgmt/users",
            name: "Users",
            component: Users
        },
        {
            appModuleId: "users",
            path: "/user-mgmt/add-user",
            name: "Add User",
            component: NewUser
        },
        {
            appModuleId: "external-user-mgmt",
            path: "/user-mgmt/external-user-mgmt",
            name: "External Users Management",
            component: ExternalUsersMgmt
        }
    ]
};

const facebookToolsRoutes = {
    path: "/facebook-tools",
    name: "Facebook",
    appModuleId: "facebook-interface",
    icon: FacebookIcon,
    children: [
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/ab-tests",
            name: "AB Tests",
            component: FacebookABTests
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/FacebookPostTool",
            name: "Post Tool",
            component: FacebookPostTool
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/FacebookPostSwitchTool",
            name: "Post Switch Tool (Experimental)",
            component: FacebookPostSwitchTool
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/CustomAudiences",
            name: "Video Custom Audiences",
            component: CustomAudiences
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/websiteCustomAudience",
            name: "Website Custom Audiences",
            component: WebsiteCustomAudienceForm
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/pageCustomAudience",
            name: "Page Custom Audiences",
            component: PageCustomAudienceForm
        },
        {
            appModuleId: "facebook-interface",
            path: "/facebook-tools/LookalikesCreator",
            name: "Lookalikes Creator (Experimental)",
            component: LookalikesCreator
        },
        {
            appModuleId: "facebook-interface",
            path: "/upload-folder-management/UploadFolderManagement",
            name: "Upload Folder Management",
            component: UploadFolderManagement
        },
        {
            appModuleId: "facebook-interface",
            path: "/accelerated-adset-tool/:accountId/:productId/:accountPlatformId",
            name: "Accelerated Adset Management",
            component: AcceleratedAdsetsTool
        },
        {
            appModuleId: "facebook-interface",
            path: "/adsets-tools/StopLoss",
            name: "Stop Loss",
            component: StopLoss
        },
        {
            appModuleId: "facebook-interface",
            path: "/budget-tool/BudgetTool",
            name: "Budget Tool",
            component: BudgetTool
        },
        {
            appModuleId: "facebook-interface",
            path:"/adsets-tools/AutomaticAdsetManagement",
            name: "Automatic Adset Management",
            component: AutomaticAdsetManagement
        }
    ]
}

const qr = {
    path: "/QR-management",
    name: "QR Management",
    appModuleId: "accountscd",
    icon: QrCode2Icon,
    component:QRManagement
}

const toolsHeader = {
    isHeader: true,
    header: "Tools"
}

const tableauAdminRoutes = {
    appModuleId: "tableaus",
    path: "/tableau-settings",
    name: "Tableau Settings",
    icon: SettingsIcon,
    children: [
        {
            appModuleId: "tableaus-internal-management",
            path: "/tableau-settings/tab-menu-items",
            name: "Edit Tab Menu Items",
            component: TabMenuItems
        },
        {
            appModuleId: "tableaus-crs-tabs-management",
            path: "/tableau-settings/TabManagement",
            name: "CRS Tabs Management",
            component: TabManagement
        },
        {
            appModuleId: "country-selection",
            path: "/tableau-settings/countryDisplaySelection",
            name: "Country Display Selection",
            component: countryDisplaySelection
        }
    ]
};

const tableauHeader = {
    isHeader: true,
    header: "Reports"
}

const tableauViewRoutes = {
    appModuleId: "tableaus",
    path: "/tableaus/internal-reports",
    name: "Tableaus",
    icon: DatabaseIcon,
    children: [
        {
            appModuleId: "tableaus",
            path: "/tableaus/internal-reports/tableau-embed/:permalink",
            name: "Tableau Embed",

            component: InternalReports
        }
    ]
};


const funnelRoutes = {
    appModuleId: "accounts",
    path: "/funnels",
    name: "Funnels",
    icon: FunnelIcon,
    children: [
        {
            appModuleId: "accounts",
            path: "/funnels/account-funnels",
            name: "Funnels",
            component: AccountFunnels
        },
        {
            appModuleId: "accounts",
            path: "/funnels/funnel-history",
            name: "Funnel History",
            component: FunnelHistory
        }
    ]
};

const cms = {
    appModuleId: "cms",
    path: "/cms",
    name: "LP CMS",
    icon: LayersIcon,
    component: CMSMain
};

const sharedComponent = {
    appModuleId: "DeploymentSharedComponent",
    path: "/deploy-shared-component",
    name: "Deploy-shared-component",
    icon: LayersIcon,
    component: DeploymentSharedComponent
};

const dailyCap = {
    appModuleId: "daily-cap-tool",
    path: "/daily-cap-tool",
    name: "Daily Cap tool",
    icon: PauseCircleIcon,
    children: [
        {
            appModuleId: "daily-cap-tool",
            path: "/daily-cap-tool/daily-cap",
            name: "Daily Caps",
            component: DailyCap
        },
        {
            appModuleId: "daily-cap-tool",
            path: "/daily-cap-tool/new-daily-cap",
            name: "New Daily Cap",
            component: NewDailyCap
        }
    ]
};
 
const stopLoss = {
    appModuleId: "facebook-interface",
    path: "/adsets-tools",
    name: "Stop Loss",
    icon: Pause,
    children: [
        {
            appModuleId: "facebook-interface",
            path: "/adsets-tools/StopLoss",
            name: "Stop Loss",
            component: StopLoss
        },
        {
            appModuleId: "facebook-interface",
            path: "/adsets-tools/AutoStopLoss",
            name: "Auto Stop Loss",
            component: AutoStopLoss
        }

    ]
}; 

// This is used for the routing
export const dashboard = [
    crmRoutes,
    prodsAndAccountsRoutes,
    funnelRoutes,
    usersRoutes,
    cms,
    sharedComponent,
    dailyCap,
    facebookToolsRoutes,
    stopLoss,
    qr,
    tableauViewRoutes,
    tableauAdminRoutes,
    clientReports,
    clientReportsInternalUsersView
]

// This is used for the nav menu
export default [
    crmRoutes,
    prodsAndAccountsRoutes,
    funnelRoutes,
    usersRoutes,
    cms,
    dailyCap,
    toolsHeader,
    facebookToolsRoutes,
    stopLoss,
    qr,
    tableauHeader,
    tableauAdminRoutes,
    clientReports,
    clientReportsInternalUsersView
]