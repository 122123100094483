import { DataGridPremium, GRID_AGGREGATION_FUNCTIONS }  from '@mui/x-data-grid-premium';
import { ROIAggregation, ROIMinus1Hour, ROIMinus1Day, ROIMinus1DayNow, ROIMinus1DayMinus1Hour, ROIMinus7Days, ROIMinus7DaysMinus1Hour, CPABEToday, CPAToday, CPAMinus1Day, CPAMinus1DayNow, AOVToday, AOVMinus1Day, CPAMinus1Hour, NCRToday, CPM, CTR } from "./adsetDataConstants";
import { LicenseInfo } from '@mui/x-license';

import clsx from 'clsx';
import React from "react";
import { Button } from "react-bootstrap";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import * as Icon from "react-feather";
import { Input, Label } from 'reactstrap';
import MediaQuery from 'react-responsive';
import AdsetViewHelper from './AdsetViewHelper';
import * as adsetUtils from '../../../../utils/adsetUtils';


LicenseInfo.setLicenseKey(
    'eeb39e1109486bc6908b51c043f745a5Tz04ODYwMixFPTE3NDQ5NTI5ODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);
const options = {
    sizePerPage: 5,
    custom: true,
};
const accelerated = "acclerated";

const bidPercentageColor = {
    green: 1.1, //10%
    yellow: 1.05, //5%
    aqua: 100 //manual
};

export default class AdsetDataView extends React.PureComponent {
    constructor(props) {
        super(props)
        this.helper = new AdsetViewHelper();
        this.cellSaved = this.cellSaved.bind(this)
        this.toggleIsVisible = this.toggleIsVisible.bind(this);
        this.toggleIsClickedRow = this.toggleIsClickedRow.bind(this);
        this.revertAdsetChanges = this.revertAdsetChanges.bind(this);
        this.flattenObj = this.flattenObj.bind(this);
        this.flattenDtos = this.flattenDtos.bind(this);
        this.saveAll = this.saveAll.bind(this);
        this.updateBidByPercentage = this.updateBidByPercentage.bind(this);
        this.saveManualBid = this.saveManualBid.bind(this);
        this.setColumnListWithSFData = this.setColumnListWithSFData.bind(this);
        this.toggleYesterDayResults = this.toggleYesterDayResults.bind(this);
        this.toggleFilterState = this.toggleFilterState.bind(this);
        this.handleProcessRowUpdate = this.handleProcessRowUpdate.bind(this);

        this.state = {
            adsets: [],
            initState: [],
            changedRows: [],
            userActionAdsets: [],
            columns: [],
            showYesterdayResults: false,
            bidAmount: null,
            filterStatus: false
        }
    }

    flattenObj(value, currentKey) {
        let result = {};
        if (value == null && currentKey != null) {
            result[currentKey] = value;
        } else {
            Object.keys(value).forEach(key => {
                const tempKey = currentKey ? `${key}` : key;
                if (typeof value[key] !== "object") {
                    result[tempKey] = value[key];
                } else {
                    result = { ...result, ...this.flattenObj(value[key], tempKey) };
                }
            });
        }

        return result;
    }


    flattenDtos(dtos) {
        return dtos.map(dto => this.flattenObj(dto));
    }


    setColumnListWithSFData(exampleData, formCols) {
        Object.keys(exampleData).forEach(example => {
            let col = {
                dataField: example,
                text: example,
                sort: false, editable: false,
                headerStyle: { width: "75px" },
            }
            formCols.push(col);

        });
        return formCols;
    }

    componentDidMount() {

        if (this.props.adsets !== null || this.props.adsets.length > 0) {
            let flattenedAdsets = this.flattenDtos(this.props.adsets);
            let formCols = this.helper.setGridColsFull(this.revertAdsetChanges, this.toggleIsVisible, this.props.isMobile ? 90 : 240, this.toggleFilterState, this.props.isCampaignView); 
            this.setState({ adsets: flattenedAdsets, initState: [...JSON.parse(JSON.stringify(flattenedAdsets))], columns: formCols })
        }
    }

    toggleIsClickedRow(checked, row) {
        let userActionAdsets = [...this.state.userActionAdsets];
        let adsets = [...this.state.adsets];
        let rowIndex = userActionAdsets.findIndex(adset => adset.acceleratedAdsetId === row.adsetId);
        if (rowIndex != -1) {
            let updatedRow = { ...userActionAdsets[rowIndex] };
            updatedRow.isClicked = checked;
            if (checked == false) {
                //if user unclicked - remove from user action 
                let userActionAdsetsIndex = userActionAdsets.findIndex(ua => ua.acceleratedAdsetId == row.adsetId);
                if (userActionAdsetsIndex !== -1) {
                    userActionAdsets.splice(userActionAdsetsIndex, 1)
                }
            }
        } else {
            let newRow = {
                acceleratedAdsetId: row.adsetId,
                isClicked: checked,
                bidAmount: row.bidAmount
            }
            userActionAdsets.push(newRow);
        }

        let adsetIndex = adsets.findIndex(adset => adset.adsetId === row.adsetId);
        if (adsetIndex != -1) {
            let updatedAdsetRow = { ...adsets[adsetIndex] };
            updatedAdsetRow.isChanged = true;
            updatedAdsetRow.isClicked = checked;
            adsets.splice(adsetIndex, 1, updatedAdsetRow);
            this.setState({ userActionAdsets: userActionAdsets, adsets: adsets });
        }

    }

    toggleIsVisible(checked, row) {
        let userActionAdsets = [...this.state.userActionAdsets];
        let adsets = [...this.state.adsets];
        let rowIndex = userActionAdsets.findIndex(adset => adset.acceleratedAdsetId === row.adsetId);
        let adsetIndex = adsets.findIndex(adset => adset.adsetId === row.adsetId);

        if (this.state.initState[adsetIndex].isActive === checked) {
            this.deleteActionFromUserActionAdsets(userActionAdsets, "isActive", rowIndex);
        } else {
            if (rowIndex != -1) {
                let updatedRow = { ...userActionAdsets[rowIndex] };
                updatedRow.isActive = checked;
                userActionAdsets.splice(rowIndex, 1, updatedRow);
            } else {
                let newRow = {
                    acceleratedAdsetId: row.adsetId,
                    isActive: checked
                }
                userActionAdsets.push(newRow);
            }

        }

        if (adsetIndex != -1) {
            adsets[adsetIndex].isChanged = true;
            adsets[adsetIndex].isActive = checked;
            this.setState({ userActionAdsets: userActionAdsets, adsets: adsets });
        }
    }

    revertAdsetChanges(adset) {
        let newAdsets = [...this.state.adsets];
        let userActionAdsets = [...this.state.userActionAdsets];
        let rowIndex = newAdsets.findIndex(test => test.adsetId == adset.adsetId);
        let userActionAdsetsIndex = userActionAdsets.findIndex(ua => ua.acceleratedAdsetId == adset.adsetId);
        if (rowIndex != -1) {
            let updatedRow = { ...this.state.initState[rowIndex] };
            updatedRow.isChanged = false;
            newAdsets.splice(rowIndex, 1, updatedRow);
            if (userActionAdsetsIndex !== -1) {
                userActionAdsets.splice(userActionAdsetsIndex, 1)
            }
            this.setState({ adsets: newAdsets, userActionAdsets: userActionAdsets });

        }

    }

    cellSaved(oldValue, newValue, row, field) {
        let adsets = [...this.state.adsets];
        let adsetIndex = adsets.findIndex(adset => adset.adsetId === row.adsetId);
        let userActionAdsets = [...this.state.userActionAdsets];
        let rowIndex = userActionAdsets.findIndex(adset => adset.acceleratedAdsetId === row.adsetId);

        if (newValue === undefined) {
            adsets[adsetIndex][field] = oldValue;
            this.setState({ adsets: adsets });
            return;
        }
        if (oldValue != newValue) {
            if (this.state.initState[adsetIndex][field] == newValue) {
                if (rowIndex != -1) {
                    this.deleteActionFromUserActionAdsets(userActionAdsets, field, rowIndex);
                }
            } else {
                if (rowIndex != -1) {
                    let updatedRow = { ...userActionAdsets[rowIndex] };
                    updatedRow[field] = newValue;
                    userActionAdsets.splice(rowIndex, 1, updatedRow);
                } else {
                    let newRow = {
                        acceleratedAdsetId: row.adsetId
                    }
                    newRow[field] = newValue;
                    if(field === "dailyBudget"){
                        if(!adsetUtils.isDailyAdset(row.adset_schedule_days)){
                            let budgetDelta = newValue - oldValue;
                            let adset = adsets[adsetIndex];
                            newRow.budget = adset.budget + budgetDelta * adsetUtils.calculateDaysRemaining(adset.adset_schedule_days, adset.adset_end_time);
                        }
                    }
                    userActionAdsets.push(newRow);
                }
            }

            if (adsetIndex != -1) {
                let adset = adsets[adsetIndex];
                let updatedRow = { ...adset };
                updatedRow.isChanged = true;
                adset[field] = newValue;
                if (field === "dailyBudget") {
                    if (adsetUtils.isDailyAdset(adset.adset_schedule_days)) {
                        adset.budget = newValue;
                    } else {
                        let budgetDelta = newValue - oldValue;
                        let calculatedDaysLeftToRun = adsetUtils.calculateDaysRemaining(adset.adset_schedule_days, adset.adset_end_time);
                        adset.budget += budgetDelta * calculatedDaysLeftToRun;
                    }
                    adset.budgetRemaing = adset.dailyBudget - adset['Spend Today'];
                }
                this.setState({ userActionAdsets: userActionAdsets, adsets: adsets });
            }
        }
    }

    deleteActionFromUserActionAdsets(userActionAdsets, field, rowIndex) {
        delete userActionAdsets[rowIndex][field];
        if (Object.keys(userActionAdsets[rowIndex]).length === 1) {
            userActionAdsets.splice(rowIndex, 1);
        }
    }

    saveAll() {
        this.props.submitFunction(this.state.userActionAdsets);
    }

    setInitialBidAmount(userAction) {
        userAction.bidAmount = this.state.adsets.find(adset => userAction.acceleratedAdsetId === adset.adsetId).bidAmount;
    }

    updateBidByPercentage(bidPercentage) {
        let userActionAdsets = [...this.state.userActionAdsets];
        userActionAdsets.forEach((userAction) => {
            this.setInitialBidAmount(userAction);
            userAction.bidAmount *= bidPercentage;
            userAction.increasedBidPercentage = bidPercentage;
        })
        this.props.submitFunction(userActionAdsets);

    }

    saveManualBid(bid) {
        let userActionAdsets = [...this.state.userActionAdsets];
        userActionAdsets.forEach((userAction) => {
            userAction.bidAmount = bid;
            userAction.increasedBidPercentage = 100;

        });
        this.props.submitFunction(userActionAdsets);
    }

    toggleYesterDayResults() {
        let showYesterdayResults = this.state.showYesterdayResults ? false : true;
        let newColumnsList = this.helper.toggleYesterDayResults(showYesterdayResults, [...this.state.columns]);
        this.setState({ columns: newColumnsList, showYesterdayResults: showYesterdayResults });
    }

    handleNextPage = ({ page, onPageChange }) =>
        () => {
            let totalPaginationPages = Math.ceil(this.state.adsets.length / options.sizePerPage);
            if (totalPaginationPages < page + 1) {
                return;
            }
            onPageChange(page + 1);
        }

    handlePrevPage = ({ page, onPageChange }) =>
        () => {
            onPageChange(page - 1 == 0 ? page : page - 1);
        }

    handleSizePerPage = ({ page, onSizePerPageChange },
        newSizePerPage) => {
        onSizePerPageChange(newSizePerPage, page);
    }

    viewAll = ({ page, onSizePerPageChange }) => {
        onSizePerPageChange(this.state.adsets.length, page);
    }

    toggleFilterState() {
        this.setState(prevState => ({
            filterStatus: !prevState.filterStatus
        }))
    }

    handleProcessRowUpdate (newRow, oldRow) {
        const updatedRows = this.state.adsets.map((row) => (row.adsetId === newRow.adsetId ? newRow : row));
        this.setState({ adsets: updatedRows });
        
        return newRow;
    };

    render() {
        let hasUserAction = this.state.userActionAdsets.length > 0;
        let manualBidChanged = this.state.bidAmount !== null && this.state.bidAmount !== "";

        const rows = [...this.state.adsets];
        const hideColumnsOnMobile = this.props.hideColumnsOnMobile !== undefined ? this.props.hideColumnsOnMobile : '';
        if (this.state.columns !== undefined && this.state.columns.length > 0) {
            return (
                <>
                    <div id="adsetTableContainer" className="adsetTableContainer" >
                        <MediaQuery maxWidth={1024} >
                                <Button className="btnScroll" size="sm" onClick={() => {
                                    document.getElementById("adsetTableContainer").scrollIntoView()}}>
                                    <Icon.ArrowUp />
                                </Button>
                        </MediaQuery>

                        <DataGridPremium
                            sx={{ width: '100%', height: 'calc(100vh - 50px)', maxHeight: '700px'}}
                            rows={rows}
                            rowHeight={this.props.isMobile ? 56 :  90}
                            getRowClassName={(params) => {
                                let classString = adsetUtils.isDailyAdset(params.row.adset_schedule_days) ? "" : "lifetime";
                                return classString;
                            }}
                            localeText={{
                                filterOperatorNoContain: "Does not contain",
                                headerFilterOperatorNoContain: "Does not contain"
                            }}
                            columns={this.state.columns}
                            aggregationFunctions={{
                                ...GRID_AGGREGATION_FUNCTIONS,
                                roiAggregation: ROIAggregation,
                                roiMinus1Hour: ROIMinus1Hour,
                                roiMinus1Day: ROIMinus1Day,
                                roiMinus1DayNow: ROIMinus1DayNow,
                                roiMinus1DayMinus1Hour: ROIMinus1DayMinus1Hour,
                                roiMinus7Days: ROIMinus7Days,
                                roiMinus7DaysMinus1Hour:ROIMinus7DaysMinus1Hour,
                                cpaBEToday: CPABEToday,
                                cpaToday: CPAToday,
                                aovToday: AOVToday,
                                aovMinus1Day: AOVMinus1Day,
                                cpaMinus1Hour: CPAMinus1Hour,
                                cpaMinus1Day: CPAMinus1Day,
                                cpaMinus1DayNow: CPAMinus1DayNow,
                                ncrToday: NCRToday,
                                cpm: CPM,
                                ctr: CTR
                            }}
                            disableColumnReorder={false}
                            initialState={{
                                aggregation: {
                                    model: {
                                      "Spend Today": 'sum',
                                      "ROI - Today": 'roiAggregation',
                                      "ROI -1 hour": 'roiMinus1Hour', 
                                      "ROI -1 Day": 'roiMinus1Day',
                                      "ROI -1 Day Now": 'roiMinus1DayNow',
                                      "ROI -1 Day -1 hour": 'roiMinus1DayMinus1Hour',
                                      "ROI -7 Days": 'roiMinus7Days',
                                      "ROI -7 Days -1 hour": 'roiMinus7DaysMinus1Hour',
                                      "CPA-BE Today": 'cpaBEToday',
                                      "CPA Today": 'cpaToday',
                                      "CPA -1 Day": 'cpaMinus1Day',
                                      "CPA -1 Day Now": 'cpaMinus1DayNow',
                                      "AOV Today": 'aovToday',
                                      "AOV -1 Day": 'aovMinus1Day',
                                      "CPA -1 hour": 'cpaMinus1Hour',
                                      "NCR Today": 'ncrToday',
                                      "cpm": 'cpm',
                                      "ctr": 'ctr',
                                      "Spend -1 hour": 'sum',
                                      "Spend -1 Day -1 hour": 'sum',
                                      "Spend -7 Days -1 hour": 'sum',
                                      budget: 'sum',
                                      dailyBudget: 'sum',
                                      budgetRemaing: 'sum',
                                      "Sales BE Today": 'sum',
                                      "Conv Today": 'sum',
                                      "Conv -1 hour": 'sum',
                                      "Revenue BE Today": 'sum',
                                      "Spend -1 Day now": 'sum',
                                      "Sales BE -1 Day": 'sum',
                                      "Revenue BE -1 Day": 'sum',
                                      "Conv -1 Day": 'sum',
                                      "initiate_checkout_today": 'sum',
                                    },
                                },
                                density: "compact",
                                pinnedColumns: { left: ['isActive', 'adsetName'], right: ['revert'] },
                                columns: {
                                    columnVisibilityModel: {
                                        ...this.props.hiddenColumns,
                                        ...hideColumnsOnMobile
                                    },
                                    orderedFields: this.props.type === accelerated ? [...this.props.reorderColumns] : true,
                                },
                                sorting: {
                                    sortModel: [{ field: 'Spend Today', sort: 'desc' }],
                                }
                            }}
                            getRowId={(row) => row.adsetId} //setting ID for rows incase id fields not found in rows Array
                            onCellEditStop={(params, event) => {
                                this.cellSaved(params.value, event.target.value, params.row, params.field);

                            }}
                            processRowUpdate={this.handleProcessRowUpdate}
                            headerFilters={this.state.filterStatus}
                        />

                        <div className="adsetTableContainer__btnWrap pt-4 mt-md-4">
                                <Button disabled={hasUserAction == false} className="submit-btn col-12 col-md-3" variant="primary" onClick={this.saveAll}>Save All</Button>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <Icon.Loader></Icon.Loader>
            )
        }
    }
}