import React from "react";
import {
    Button,
    Card,
    Container,
    Form, Modal,
    Spinner
} from "react-bootstrap";
import { genericFetcherFactory } from "../../../utils/requestUtils";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { loadUser } from "../../../redux/actions/userContextActions";

const autoStopLossPage = "https://scali.qualityscore.co/dashboard/adsets-tools/AutoStopLoss";

class AutomaticAdsetManagement extends React.PureComponent {
    constructor(props) {
        super(props);

        this.toggleIsAccountInAdsetAutomation = this.toggleIsAccountInAdsetAutomation.bind(this);
        this.saveAllChanges = this.saveAllChanges.bind(this);
        this.validateAccountSelection = this.validateAccountSelection.bind(this);

        this.state = {
            activeAccounts: [],
            showingSavePrompt: false
        }
    }

    componentDidMount() {
        this.getActiveAccounts();
    }

    getActiveAccounts() {
        genericFetcherFactory(`/api/accounts/Accounts/activeAccountIdListFacebook`,
            "ACTIVE_ACCOUNTS", "Failed to fetch active accounts")().then(result => {
                if (result.success === true) {
                    this.setState({ activeAccounts: result.data });
                    return result.data;
                }
            });
    }
    //TODO add user info

    validateAccountSelection() {
        const hasChangedAccounts = this.state.activeAccounts.some(account => account.isChanged === true);
        if (hasChangedAccounts) {
            if (this.state.activeAccounts.some(account => account.isAccountInAdsetAutomation === true
                && account.isAccountInAutoStopLoss === false)) {
                toastr.warning("Please activate the account on Auto Stop Loss Page");
                return false;
            } else {
                this.saveAllChanges();
            }
        }
    }

    saveAllChanges() {
        this.setState({ showingSavePrompt: true });
        let accountsListToSave = this.state.activeAccounts.filter(row => row.isChanged === true);

        if (accountsListToSave.length <= 0) {
            toastr.warning("No pending changes to save");
            this.setState({ showingSavePrompt: false });
        } else {
            genericFetcherFactory(`/api/accounts/Accounts/updateAutomationAccountList/?userLogin=${this.props.user.userLogin}`, "UPDATE_AUTO_SL_ACCOUNT_LIST",
                "Failed to update", "PATCH", {
                method: "PATCH",
                body: JSON.stringify(accountsListToSave),
                headers: { "Content-Type": "application/json" }
            })().then((res) => {
                this.setState({ showingSavePrompt: false });

                if (res.success) {
                    toastr.success("Updated Status Successfully")
                }
            }).catch(error => {
                console.log(error);
            });

        }
    }

    columns = [
        {
            dataField: "accountId",
            text: "Account ID",
            headerStyle: { width: "60px" },
            sort: true,
            editable: false
        },
        {
            dataField: "accountName",
            text: "Account Name",
            sort: true,
            editable: false,
            filter: textFilter({ placeholder: '', style: { width: "50%" } }),
        },
        {
            dataField: "isAccountInAdsetAutomation",
            text: "Account Adset Automation Status",
            sort: true,
            headerStyle: { width: "300px" },
            align: "center",
            formatter: (cell, row, rowIndex) => this.checkboxFormatter(cell, row, rowIndex, { toggleChecked: this.toggleIsAccountInAdsetAutomation })
        }
    ];

    checkboxFormatter(cell, row, index, { toggleChecked }) {
        return <Form.Check
            type="switch"
            id={`row-${row.accountId}`}
            name={`row-${row.accountId}`}
            checked={row.isAccountInAdsetAutomation}
            onChange={event => toggleChecked(event.target.checked, row)}
        />
    }

    toggleIsAccountInAdsetAutomation(checked, row) {
        let newActiveAccounts = [...this.state.activeAccounts];
        let userActionrowIndex = newActiveAccounts.findIndex(_row => _row.accountId == row.accountId);
        if (userActionrowIndex != -1) {
            //validate
            // if (checked === true && newActiveAccounts[userActionrowIndex].isAccountInAutoStopLoss === false) {
            //     toastr.warning("Please activate the account on Auto Stop Loss Page First");
            //     return false;
            // }
            let updated = { ...newActiveAccounts[userActionrowIndex], isAccountInAdsetAutomation: checked, isChanged: true };
            newActiveAccounts.splice(userActionrowIndex, 1, updated);
            this.setState({ activeAccounts: newActiveAccounts });
        }
    }

    render() {
        return (
            <Container fluid className="p-0">
                <Modal
                    isOpen={this.state.showingSavePrompt === true}
                    centered
                >
                    <Modal.Header>
                        Saving Accounts Selection...
                    </Modal.Header>
                    <Modal.Body className="text-center m-3">
                        <Spinner color="dark" className="mr-2" />
                    </Modal.Body>
                </Modal>

                <Card>
                    <Card.Header>
                        <Card.Title tag="h1" className="mb-0">
                            <h3> Adset Automation </h3>
                        </Card.Title>
                        <h5>If you want to enable or disable ad set automation for an account, you can do it here.
                            <br />
                            Please make sure to activate the account on <a href={autoStopLossPage} target="_blank">Auto Stop Loss Page</a></h5>
                    </Card.Header>
                    <Card.Body>
                        {this.state.showingSavePrompt == true &&
                            <Spinner color="dark" className="mr-2" />
                        }
                        <BootstrapTable
                            bootstrap4
                            keyField="accountId"
                            bordered={true}
                            striped
                            hover
                            data={this.state.activeAccounts ? this.state.activeAccounts : []}
                            columns={this.columns}
                            filter={filterFactory()}
                        />
                        <Button className="submit-btn mt-4" color="primary" onClick={this.saveAllChanges}>Save All</Button>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}
export default connect((store) => {
    return {
        user: store.userContext.user
    }
}, { loadUser })(AutomaticAdsetManagement)

